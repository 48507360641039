<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">发布记录</span>
		</div>
		<div class="bg-white p-2 m-2">
			<div class="d-flex align-items-center">
				<div class="d-flex align-items-center m-1">
					<el-input style="width: 200px" size="small" v-model="search.keyword" placeholder="关键字" clearable
						@clear="toSearch" @keyup.enter.native="toSearch">
					</el-input>
				</div>
				<div class="d-flex align-items-center m-2">
					<div class="label ">类型：</div>
					<el-select size="small" style="width: 120px" v-model="search.status" @change="toSearch"
						placeholder="类型">
						<el-option label="全部" :value="0"></el-option>
						<el-option label="所有单位" :value="10"></el-option>
						<el-option label="指定单位" :value="20"></el-option>
					</el-select>
				</div>
				<el-button class="ml-1" @click="toSearch" type="primary" size="mini" icon="el-icon-search"></el-button>
				<router-link :to="{name:'letter/add'}" v-auth="'letter/add'">
					<el-button class="ml-1" @click="toSearch" type="primary" size="mini">
						添加
					</el-button>
				</router-link>
			</div>
			<el-table border class="mt-3" :data="lists.data" style="width: 100%">
				<el-table-column align="center" label="ID">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.letter_id}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="标题">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.title}}</span>
					</template>
				</el-table-column>
				</el-table-column>
				<el-table-column align="center" label="类型">
					<template slot-scope="scope">
						<template v-if="scope.row.type == 10">
							<el-tag type="danger">所有单位</el-tag>
						</template>
						<template v-else-if="scope.row.type == 20">
							<el-tag type="success">指定单位</el-tag>
						</template>
					</template>
				</el-table-column>
				<el-table-column align="center" label="添加时间">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.create_time}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作">
					<template slot-scope="scope">
						<el-button type="text" size="mini" @click="openDialog(scope.row)" class="table-btn">详情
						</el-button>
						<el-button type="text" size="mini" @click="deleteItem(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="lists.per_page" :current-page.sync="search.page"
					style="display: inline-block;float: right;" background @current-change="pageChange"
					layout="prev, pager, next" :total="lists.total">
				</el-pagination>
			</div>
			<el-dialog title="信件发布详情" :visible.sync="dialogVisible" top="3vh" width="650px" height="60%">
				<!-- 添加/修改规则 -->
				<el-form ref="form" :model="form" label-width="100px">
					<el-form-item label="标题" prop="title">
						<el-input v-model="form.title" placeholder="名称"></el-input>
					</el-form-item>
					<el-form-item label="发布类型" prop="type">
						<el-radio v-model="form.type" :value="10" :label="10">
							所有单位
						</el-radio>
						<el-radio v-model="form.type" :value="20" :label="20">
							指定单位
						</el-radio>
					</el-form-item>
					<el-form-item label="指定单位" v-if="form.type == 20">
						<p class="m-0 p-0" v-for="(item,index) in form.unit_list">
							<span style="font-weight: 800;">{{item.first_unit_name}}</span>
							<template v-if="item.second_unit_name != ''">
								<span style="font-weight: 800;">- {{item.second_unit_name}}</span>
							</template>
							<template v-if="item.third_unit_name != ''">
								<span style="font-weight: 800;">- {{item.third_unit_name}}</span>
							</template>
						</p>
					</el-form-item>
					<el-form-item label="内容">
						<app-rich-text :key="timer" v-model="form.content"></app-rich-text>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogVisible = false">关闭</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import appRichText from '@/components/app-rich-text.vue';
	export default {
		inject: ['layout'],
		components: {
			appRichText
		},
		data() {
			return {
				lists: [],
				timer: new Date().getTime(),
				search: {
					s: 'store/letter/index',
					keyword: '',
					type: 0,
					page: 1,
				},
				form: {
					title: "",
					content: "",
					type: 10,
					unit_list: [],
				},
				dialogVisible: false
			}
		},
		created() {
			this.getList();
		},
		methods: {
			// 打开弹出层
			openDialog(item) {
				this.timer = new Date().getTime()
				this.form = {
					title: item.title,
					content: item.content,
					type: item.type,
					unit_list: item.unit_list,
				}
				// 显示弹出层
				this.dialogVisible = true
			},
			toSearch() {
				this.search.page = 1;
				this.getList()
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 删除单个
			deleteItem(data) {
				this.$confirm('是否要删除该改发布记录吗?', '提示', {
					confirmButtonText: '删除',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.layout.showLoading()
					this.axios({
						token: true,
						params: {
							s: 'store/letter/delete',
							letter_id: data.letter_id
						},
						method: 'get',
					}).then(res => {
						if (res.data.code == 1) {
							this.$message({
								message: res.data.msg,
								type: 'success'
							});
							this.getList()
						}
						this.layout.hideLoading()
					}).catch(err => {
						this.layout.hideLoading()
					})
				})
			},
		},
	}
</script>
<style>
</style>
